import React from 'react'
import Dashboard from '../components/MainDrawer'

function Home() {
    return (
        <>
            <Dashboard />
        </>
    )
}

export default Home